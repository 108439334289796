:root:root {
    --adm-color-primary: #53AE2A;
}
body,html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    user-select: none;
    height: 100%;
}
.adm-list-body {
    border: none !important;
}

.ant-tabs.customerTab > .ant-tabs-nav {
    display: none;
}

.ant-tabs-nav-wrap {
    justify-content: center;
}
.ant-tabs-tab {
    flex: 1!important;
}
.ant-tabs-nav-list {
    width: 100%;
    z-index: 1000;
    text-align: center;
}
.ant-tabs-tab-btn {
    margin: 0 auto;
}
.ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
    color: #333 !important;
    font-weight: 500;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 64px !important;
    background-color: transparent !important;
}
.ant-tabs-tab.ant-tabs-tab-active::after{
    content: "";
    position: absolute;
    width: 64px;
    height: 3px;
    bottom: 0;
    left: calc(50% - 32px);
    background: url("https://public-1253450979.file.myqcloud.com/calculator/icon/tab-active.svg") no-repeat center !important;
}
.ant-tabs-nav-operations {
    display: none !important;
    visibility: hidden !important;
}

.ant-tabs.ant-tabs-top.fixTabs .ant-tabs-nav {
    position: fixed;
    width: 100%;
    top: 45px;
    background: #fff;
    z-index: 10000;
}
.ant-tooltip {
    z-index: 5;
}
.ant-tooltip-arrow::before {
    background: #53AE2A !important;
}
.ant-tooltip-inner {
    padding: 0 8px !important;
    min-height: auto !important;
    background: #53AE2A !important;
    text-align: center;
    color: white;
}

input::placeholder {
    text-align: right!important;
}
 input#password::placeholder {
    text-align: left !important;
}
